<template>
  <div>
    <div class="header">
      <img class="bg" src="/img/otherBg.png" alt="" />
      <div class="content">
        <div>
            <div class="title">人才招聘</div>
            <div class="desc">用科技创造未来 /<br/>循环和再生，让地球更年轻</div>
        </div>  
      </div>
    </div>
    <div class="first">
      <el-row :gutter="20">
           <el-col :xs="24" :sm="24" :md="18" :lg="18" :xl="18">
               <div class="card" v-for="it in list" :key="it.id">
                 <div class="title" @click="active = it.id == active ? null : it.id">
                     <p>{{it.post}}</p>
                     <span>招聘人数：{{it.personNum}}</span>
                     <span class="jg"></span>
                     <span>发布时间：{{it.time}}</span>
                 </div>
                 <i class="el-icon-arrow-up icon" v-if="it.id == active" @click="active = it.id == active ? null : it.id" ></i>
                 <i class="el-icon-arrow-down icon" v-else @click="active = it.id == active ? null : it.id" ></i>
                 
                 <div class="rich" v-show="it.id == active">
                   <div class="hr"></div>
                   <rich :doc="it.html"></rich>
                 </div>
                 
               </div>
           </el-col>
           <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
             <div class="contact">
               <p>联系方式</p>
               <!-- <span>{{hrInfo.phone}}</span>
               <span>&nbsp;&nbsp;&nbsp;{{hrInfo.name}}</span> -->
               <span>{{hrInfo.email}}</span>
             </div>
           </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { getPosition,getHrInfo } from "@/api.js";
export default {
  data() {
    return {
      list: [],
      active: null,
      hrInfo: {
        phone: '',
        name: '',
        email: ''
      }
    };
  },
  components: {
    rich:()=>import("@/components/rich")
  },
  async mounted() {
    let res =await getPosition()
    this.list = res.data

    let res2 = await getHrInfo()
    this.hrInfo = res2.data
  }
};
</script>

<style scoped lang="scss">
@import "./header.scss";

.first{
  width: 80%;
  max-width: 1520px;
  margin: 0 auto;
  padding: 16.5rem 0;
  .contact{
    background: #0670D8;
    text-align: left;
    padding: 4rem;
    box-sizing: border-box;
    p{
      font-size: 3rem;
      line-height: 1.2;
      letter-spacing: 1px;
      color: #FFFFFF;
      margin-bottom: 4rem;
      font-weight: 200;
    }
    span{
      font-size: 2rem;
      line-height: 2;
      letter-spacing: 1px;
      color: #FFFFFF;
      font-weight: 200;
    }
  }

  .card{
    padding: 4rem;
    box-sizing: border-box;
    position: relative;
    background: #F5F5F5;
    margin-bottom: 4rem;
    .title{
      p{
        font-size: 3rem;
        line-height: 1.2;
        letter-spacing: 1px;
        color: #000000;
        margin-bottom: 2rem;
      }
      span{
        font-size: 1.8rem;
        line-height: 1.2;
        letter-spacing: 1px;
        color: #666666;
      }
      width: 100%;
      padding-right: 4rem;
      box-sizing: border-box;
      cursor: pointer;
      .jg{
        display: inline-block;
        width: 4rem;
        height: 1px;
      }
    }
    .icon{
      position: absolute;
      top: 6.6rem;
      right: 5rem;
      font-size: 3rem;
      color: rgba($color: #000000, $alpha: 0.3);
      cursor: pointer;
    }

    .rich{
      padding: 4rem 0;
      .hr{
        width: 100%;
        height: 1px;
        background-color: #CCCCCC;
      }
    }

  }

}
</style>
